import i18n from '@/i18n';

// Convert the grouping structure to one the treeview component understands
export const convertGroupToFilterStructure = (groupPath, group) => {
  const userLocale = JSON.parse(localStorage.getItem('locale')) || 'nl';
  const defaultLocale = 'nl';

  const locale = group.localization[userLocale] ? userLocale : defaultLocale;

  const localization = typeof group.localization[locale] === 'string'
    ? group.localization[locale] : group.localization[locale].plural;

  return {
    groupPath,
    localization,
    children: group.subGroups
      ? Object.keys(group.subGroups).map((groupCode) => convertGroupToFilterStructure(
        `${groupPath}/${groupCode}`,
        group.subGroups[groupCode],
      ))
      : undefined,
  };
};

// TODO get this from the actual grouping
export const euInstitutionCodeToName = (code) => {
  if (code === 'EUROCOM') return i18n.t('eu.EC') || 'Europese Commissie';
  if (code === 'EUROPARL') return i18n.t('eu.EP') || 'Europees Parlement';
  if (code === 'EUROCOU') return i18n.t('eu.EuroCou') || 'Consilium';
  if (code === 'EP-COM-OVERIG') return i18n.t('eu.euEPOverig') || 'EP overig';
  return '???';
};

export const formatLocalization = (localization, groupPath) => {
  if (groupPath === 'EU-SH' || groupPath === 'EU-MD') return `${localization}*`;
  return localization;
};
